import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import Row from './Row';
import SizedBox from './SizedBox';
import styled from 'styled-components';

const FooterItems = styled(Row)`
    > * {
        margin-right: 8px;
    }
    > *:last-child {
        margin-right: 0;
    }
`;

const Footer = (props) => {
    return (
        <FooterItems mainAxis="center">
            <Link to="/terms">Terms</Link>
            <Link to="/privacy">Privacy</Link>
            <Link to="/about">About</Link>
        </FooterItems>
    );
}

export default Footer;