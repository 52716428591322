import React from 'react';
import Row from './Row';
import mortgage_house from "./../images/mortgage_house.svg";
import { Link } from 'gatsby-plugin-intl';
import styled from 'styled-components';

const Logo = styled.img`
    height: 48px;
`;

const Header = () => {
    return (
        
        <Link to="/">
            <Row crossAxis="center">
                <Logo src={mortgage_house} alt="Logo" />
                <h2>
                    Ahuna
                </h2>
            </Row>

        </Link>
    );
}

export default Header;