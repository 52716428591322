import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import Footer from "./Footer"
import Header from "./Header"
import Column from "./Column";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;

const Page = styled.div`
  width: 100%;
  max-width: 800px;
  padding-bottom: 16px;
`;

const PageWrapper = styled(Column)`
  width: 100%;
`;

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <GlobalStyle />
      <PageWrapper crossAxis="center">
        <Page>
          <Header />
          {children}
          <Footer />
        </Page>

      </PageWrapper>
    </React.Fragment>
  );
}